import { Splide } from "@splidejs/splide";
import { Grid } from "@splidejs/splide-extension-grid";

document.addEventListener('DOMContentLoaded', () =>
{
	document.querySelectorAll(".sub-brand-carousel").forEach(block =>
	{
		var splideDiv = block.querySelector('.splide') as HTMLElement;

		if (splideDiv != null)
		{
			new Splide(splideDiv, {
				pagination: false,
				gap: 28,
				width: "91%"
			}).mount();
		}
	});

	document.querySelectorAll(".communities-carousel").forEach(block =>
	{
		var splideDiv = block.querySelector('.splide') as HTMLElement;

		if (splideDiv != null)
		{
			new Splide(splideDiv, {
				pagination: false,
				gap: 28,
			}).mount();
		}
	});


	document.querySelectorAll(".vertical-card-scroller__splide").forEach(block =>
	{
		var splideDiv = block as HTMLElement;

		if (splideDiv != null)
		{
			new Splide(splideDiv, {
				pagination: false,
				gap: 20,
				mediaQuery: 'min',
				width: "90%",
				breakpoints: {
					768: {
						direction: 'ttb',
						height: "330px",
						gap: 30,
						autoHeight: true,
						perPage: 1,
						wheel: true,
						width: "100%",
						arrows: true
					}
				}
			}).mount();
		}
	});

	document.querySelectorAll(".related-content-splide").forEach(block =>
	{
		var splideDiv = block as HTMLElement;
		new Splide(splideDiv, {
			pagination: false,
			gap: 28,
			perPage: 3,
			width: "100%",
			breakpoints: {
				768: {
					perPage: 1,
				},
				992: {
					perPage: 2,
					width: "90%",
				},
			}
		}).mount();
	});

	document.querySelectorAll(".related-content-section .related-content-splide").forEach(block =>
	{
		var splideDiv = block as HTMLElement;
		new Splide(splideDiv, {
			pagination: false,
			gap: 28,
			perPage: 3,
			width: "100%",
			breakpoints: {
				768: {
					perPage: 1,
				},
				992: {
					perPage: 2,
					width: "90%",
				},
			}
		}).mount( );
	});

	document.querySelectorAll(".related-sub-brand-section .related-sub-content-splide").forEach(block =>
	{
		if (document.querySelector(".content-page__content"))
		{
			var splideDiv = block as HTMLElement;
			new Splide(splideDiv, {
				mediaQuery: 'min',
				pagination: false,
				gap: 28,
				perPage: 1,
				grid: {
					rows: 1,
					cols: 1,
					gap: {
						row: '28px',
					}
				},
				breakpoints: {
					768: {
						perPage: 1,
						grid: {
							rows: 1,
							cols: 2,
							gap: {
								row: '28px',
								col: '28px'
							}
						},
					},
					992: {
						perPage: 1,
						fixedWidth: "100%",
						grid: {
							rows: 2,
							cols: 2,
							gap: {
								row: '28px',
							}
						},
					},
				}
			}).mount({ Grid });
		} else
		{
			var splideDiv = block as HTMLElement;
			new Splide(splideDiv, {
				pagination: false,
				gap: 28,
				perPage: 4,
				width: "100%",
				breakpoints: {
					768: {
						perPage: 1,
					},
					992: {
						perPage: 2,
						width: "90%",
					},
				}
			}).mount();
		}
	});

	document.querySelectorAll(".featured-content-carousel__splide").forEach(block =>
	{
		var splideDiv = block as HTMLElement;
		new Splide(splideDiv, {
			pagination: false,
			gap: 28,
			perPage: 3,
			width: "100%",
			breakpoints: {
				768: {
					perPage: 1,
				},
				992: {
					perPage: 2,
					width: "90%",
				},
			}
		}).mount();
	});

	document.querySelectorAll(".quick-links__splider").forEach(block =>
	{
		var splideDiv = block as HTMLElement;
		new Splide(splideDiv, {
			pagination: false,
			arrows: false,
			gap: 28,
			perPage: 4,
			width: "100%",
			breakpoints: {
				768: {
					perPage: 1,
					width: "80%",
				},
				992: {
					perPage: 2,
					width: "90%",
					gap: 16,
				},
			}
		}).mount();
	});
});