declare const pdt: any;

class PardotBanner
{
	private readonly yesElementId = "pi_tracking_opt_in_yes";
	private readonly noElementId = "pi_tracking_opt_in_no";

	constructor(private readonly targetNode: HTMLElement)
	{
		console.log("Pardor Banner initialized.");

		this.watchForElement(this.yesElementId);
		this.watchForElement(this.noElementId);
	}

	private watchForElement(elementId: string): void
	{
		const observer = new MutationObserver((mutationsList: any, observer: any) =>
		{
			for (let mutation of mutationsList)
			{
				if (mutation.type === 'childList')
				{
					mutation.addedNodes.forEach((node: HTMLElement) =>
					{
						// Check if the added node is an element and has the specific ID
						if (node.nodeType === Node.ELEMENT_NODE && node.id === elementId)
						{
							console.log('Target element was added:', node);
							
							this.handleNewElement(node);
						}
						else if (node.nodeType === Node.ELEMENT_NODE)
						{
							// If the added node doesn't have the ID, check its descendants
							const foundElement = node.querySelector('#' + elementId);

							if (foundElement)
							{
								console.log('Target element was added (nested):', foundElement);
								
								this.handleNewElement(foundElement);
							}
						}
					});
				}
			}
		});

		const config = { childList: true, subtree: true };
		observer.observe(this.targetNode, config);
	}

	private handleNewElement(element: Element)
	{
		console.log('Handling new element:', element);

		element.removeAttribute("onclick");

		const isYes = element.id === this.yesElementId;

		if (isYes === true)
		{
			console.log('Opt-in link clicked: ' + isYes);

			pdt('hideConsentBanner', true);
		}
	}
}

if (document.readyState === 'loading')
{
	// If not, wait for it to load
	document.addEventListener('DOMContentLoaded', function ()
	{
		new PardotBanner(document.body);
	});
}
else
{
	// If it's already loaded, start observing immediately
	new PardotBanner(document.body);
}