import "@popperjs/core";
import "bootstrap";
import { ValidationService } from "aspnet-client-validation";
import "../styles/index.scss";

import "./components/header";
import "./components/seamless";
import "./components/select";
import "./components/tabs";
import "./components/mouseTakeover";
import "./components/splide";
import "./components/selfSegmentation";
import "./components/promoBanner";
import "./components/expandableCheckboxes";
import "./components/gatedContent";
import "./components/pardotBanner";

// ASP.NET Core Client Side Validation Setup
const service = new ValidationService();
service.bootstrap();