class PromoBanner
{
	boundingClientRectPrevRatio: number;
	intersectionPrevRatio: number;
	el: HTMLElement;
	text: HTMLElement | null;
	constructor(el: HTMLElement)
	{
		this.el = el;
		this.text = el.querySelector<HTMLElement>(".promo-banner__content__text");

		this.boundingClientRectPrevRatio = 0.0;
		this.intersectionPrevRatio = 0.0;
		this.GetTextLines();
		this.createObserver();
	}

	createObserver()
	{
		let observer;

		let options = {
			root: null,
			rootMargin: "0px",
			threshold: this.buildThresholdList(),
		};

		observer = new IntersectionObserver(this.handleIntersect, options);
		observer.observe(this.el);
	}

	handleIntersect(entries: any, observer: any)
	{
		entries.forEach((entry: any) =>
		{
			var lines = document.querySelectorAll<HTMLElement>(".line");
			var numberOfLines = lines.length;
			if (entry.boundingClientRect.y < this.boundingClientRectPrevRatio && entry.intersectionRatio < this.intersectionPrevRatio)
			{
				for (var i = 1; i < numberOfLines + 1; i++)
				{
					if (entry.intersectionRatio < 1 - ((i / 10) / 1.5))
					{
						var currentLine = lines[i - 1];
						if(currentLine != null){
							currentLine.style.backgroundPositionX = 0 + "%";
						}
						
					}
				}
			}
			this.intersectionPrevRatio = entry.intersectionRatio
			this.boundingClientRectPrevRatio = entry.boundingClientRect.y;
		});
	}

	buildThresholdList()
	{
		let thresholds = [] as any;
		let numSteps = 20;

		for (let i = 1.0; i <= numSteps; i++)
		{
			let ratio = i / numSteps;
			thresholds.push(ratio);
		}

		thresholds.push(0);
		return thresholds;
	}

	GetTextLines()
	{
		if(this.text != null){
			const text = this.text.innerText.replace(/\s+/gm, " ").trim();

			const words = text.split(" ");
	
			const spans = words.map((word) =>
			{
				return `<span style="display: inline-block;">${word}</span>`;
			});
	
			this.text.innerHTML = spans.join(" ");
	
			const lines = [] as any;
			let line = [] as string[];
			let previousTop = null as any;
	
			this.text.querySelectorAll("span").forEach((wordSpan, index) =>
			{
				const wordRect = wordSpan.getBoundingClientRect();
	
				if (previousTop !== wordRect.top && index > 0)
				{
					// Push the current line
					lines.push(line);
					// Start a new line
					line = [words[index] as string];
				} else
				{
					// Add word to the current line
					line.push(words[index] as string);
				}
	
				previousTop = wordRect.top;
			});
	
			// Push whatever words are left as the last line
			lines.push(line);
	
			let formattedLines = lines.map((line: any[]) => `<p class="line">${line.join(" ")}</p>`).join("\n");
	
			this.text.innerHTML = formattedLines;
		}
	}
}

window.addEventListener('load', () =>
{
	document.querySelectorAll<HTMLElement>(".promo-banner").forEach(el =>
	{
		new PromoBanner(el);
	});
});